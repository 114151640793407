import React from "react";
import { useRouter } from "next/router";
import { useSession, getSession } from "next-auth/client";
import Spinner from "components/Spinner";
import { GetServerSideProps } from "next";

const logoStyle: React.CSSProperties = {
  maxHeight: "55px",
};

const titleStyle: React.CSSProperties = {
  fontWeight: 200,
};

const headerStyle: React.CSSProperties = {
  marginTop: 75,
};

export default function Home() {
  const [id, setId] = React.useState("");
  const [session, loading] = useSession();

  const router = useRouter();

  const formSubmit = (event: any) => {
    event.preventDefault();
    if (id.trim()) {
      router.push({
        pathname: "/search",
        query: {
          id: id,
          tab: "details",
        },
      });
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (!session) {
    router.push("/login");
    return null;
  }

  return (
    <>
      <div className="row text-center" style={headerStyle}>
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <img src="/logo.png" style={logoStyle} />
          <span className="ms-1 text-dark fs-1" style={titleStyle}>
            Profilus
          </span>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-6 offset-md-3">
          <form>
            <input
              type="text"
              autoFocus
              autoComplete="off"
              className="form-control shadow-sm mb-3 mt-3"
              id="id"
              placeholder="Search by user ID"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
            <button
              type="submit"
              className="btn btn-dark shadow-sm w-100"
              onClick={formSubmit}
            >
              Search
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export async function getServerSideProps(context: GetServerSideProps) {
  const session = await getSession(context);
  return {
    props: { session },
  };
}
