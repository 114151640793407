import styles from "./Spinner.module.css";

type SpinnerTypes = {
  mini?: boolean;
};

export default function Spinner({ mini = false }: SpinnerTypes) {
  return (
    <div
      className={styles.spinner}
      style={mini ? { minHeight: "auto" } : {}}
    ></div>
  );
}
